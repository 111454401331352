// /* eslint-disable */
/* global ajax_object */
import Foundation from './inc/foundation';
import 'slick-carousel';
import 'jquery-match-height';
import {
  initSliderOnMobile,
  toggleVideoPlay,
  updateSliderCounter,
  customSelect,
  timelineShowMore,
  downloadBlocksShowMore,
  scrollToBlock,
  loadMoreKarriers,
} from './inc/functions';
// import './google-map';

$(document).foundation();
$('.card.hide-contact-card').remove();
// ******************************Slider Benefits************************

function madeBenefitsSlider() {
  const sections = $('.slider-benefits.js-slider-benefits');
  if (sections.length === 0) return;

  sections.each(function (_, section) {
    let slider = $(section).find('.js-slider');
    let controls = $(section).find('.button-slider__buttons');
    //let text = $(section).find('.slide_text');

    slider
      .slick({
        arrows: true,
        infinite: true,
        variableWidth: true,
        rows: 0,
        prevArrow: controls.find('.slick-prev'),
        nextArrow: controls.find('.slick-next'),
        rtl: document.dir === 'rtl',
      })
      .on('beforeChange', updateSliderCounter)
      .on('beforeChange', (e, slick) => {
        slick.$slideTrack.find('.slick-cloned .slide_text').matchHeight();
      });

    slider.slick('slickGoTo', 0);
  });

  $('.slide_text').matchHeight();
}

$(window).on('load', function () {
  madeBenefitsSlider();
});

const menuItems = $('.header-menu .menu-item-has-children > a');
/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass('is-active');
    $('.submenu-wrapper').removeClass('is-active');
    $('body').toggleClass('lock');
  })
  .on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('is-active');
    $('.submenu-wrapper').removeClass('is-active');
    menuItems.removeClass('is-active');
  });

/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});
menuItems
  .on('click', function (e) {
    e.preventDefault();
    if (Foundation.MediaQuery.is('medium down')) {
      openSubmenu(e.target);
    }
  })
  .on('mouseenter', function (e) {
    if (Foundation.MediaQuery.is('large')) {
      openSubmenu(e.target);
    }
  });

function openSubmenu(target) {
  const $this = $(target);
  const nextWrapper = $this.next('.submenu-wrapper');
  const activeClass = 'is-active';

  $('.submenu-wrapper').each(function (_, item) {
    $(item).removeClass(activeClass);
  });

  if (nextWrapper.hasClass(activeClass)) {
    nextWrapper.removeClass(activeClass);
    $this.removeClass(activeClass);
  } else {
    menuItems.removeClass(activeClass);
    nextWrapper.addClass(activeClass);
    $this.addClass(activeClass);
  }
  $this.closest('.header-menu-container').scrollTop(0);
}
$('header').on('mouseleave', function () {
  if (Foundation.MediaQuery.is('large')) {
    menuItems
      .removeClass('is-active')
      .next('.submenu-wrapper')
      .removeClass('is-active');
  }
});
function closeMenuIfClickNotSubmenu() {
  $(document).on('click', function (e) {
    let targetEl_1 = e.target.closest('.submenu-wrapper');
    let targetEl_2 = e.target.closest('.menu-item-has-children');
    let targetEl_3 = e.target.closest('.menu-icon');
    let targetEl_4 = e.target.closest('.header-menu-container__inner');
    if (!targetEl_1 && !targetEl_2 && !targetEl_3 && !targetEl_4) {
      $('.submenu-wrapper.is-active').removeClass('is-active');
      $('.menu-item-has-children > a.is-active').removeClass('is-active');
      if ($('.menu-icon').hasClass('is-active')) {
        $('.menu-icon').removeClass('is-active');
        $('#main-menu').css('display', 'none');
        $('body').removeClass('lock');
      }
    }
  });
}
closeMenuIfClickNotSubmenu();
$('.submenu-wrapper').removeClass('is-active');

$('.close-submenu').on('click', function () {
  $(this).closest('.submenu-wrapper').removeClass('is-active');
});

$(window).on('load resize', () => {
  const header = $('.header');
  header.css('--header-height', header.outerHeight() + 'px');
});

// Initialize the accordion menu
let isFooterAccordionInit = false;
$(window).on('load changed.zf.mediaquery', function () {
  if (Foundation.MediaQuery.is('large')) {
    if (isFooterAccordionInit) {
      $('.footer-menu').foundation('destroy');
      isFooterAccordionInit = false;
    }
  } else {
    if (!isFooterAccordionInit) {
      new Foundation.AccordionMenu($('.footer-menu'), {
        multiOpen: false,
      });
      isFooterAccordionInit = true;
    }
  }
});

$('.produkte-archive-card__image').matchHeight({
  byRow: true,
});

initSliderOnMobile(
  '.produkte-archive-cards .js-cards-slider',
  {
    appendArrows: '.js-cards-slider__footer .slider-buttons',
    rows: 0,
    infinite: true,
    variableWidth: true,
  },
  'medium'
);

$('.js-cards-slider')
  .on('init', function () {
    $('.produkte-archive-card__image').matchHeight({
      byRow: true,
    });
  })
  .on('beforeChange', updateSliderCounter);

initSliderOnMobile('.posts-list', {
  appendArrows: '.posts-list__buttons',
  infinite: true,
  variableWidth: true,
});
$('.posts-list').on('beforeChange', updateSliderCounter);

toggleVideoPlay();

$('.product-tabs__form')
  .on('submit', (e) => {
    e.preventDefault;
  })
  .on('change', function () {
    const values = {};
    const $this = $(this);
    const $wrapper = $this.closest('.product-tabs').find('.js-tabs-wrapper');
    const currentPostId = $this.attr('data-current-post-id');
    let activeValue;
    $.each($(this).serializeArray(), (i, field) => {
      activeValue = values[field.name] = field.value;
    });

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'GET',
      data: {
        action: 'load_product_tabs',
        product: values.product,
        currentPostId,
      },
      beforeSend: function () {
        $wrapper.addClass('loading');
      },
      success: function (resp) {
        $wrapper.html(resp);
        $(document).foundation();
        $this.find('li').css('display', 'block');
        $this.find(`li[rel=${activeValue}]`).css('display', 'none'); //hidden selected option
      },
      complete: function () {
        $wrapper.removeClass('loading');
      },
    });
  });
//======= static_4_cards block ========= Down
$('.card__bottom').matchHeight();
//======= static_4_cards block ========= Up

$('.product-tabs__form select').each((idx, item) => {
  customSelect(item);
});
$(document).on('gform_post_render', function () {
  $('.ginput_container select').each(function () {
    customSelect(this);
  });
  function stylingUploadInputGravityForm() {
    // eslint-disable-next-line no-undef
    gform.addAction('gform_input_change', function (elem, formId, fieldId) {
      if (elem.type !== 'file') return false;

      let inputParent = $(`#field_${formId}_${fieldId}`);
      let maxFileSizeCanBeeLoad = Math.floor(
        Number(inputParent.find('input[type="hidden"]').val()) / 1000
      );
      let inputFile = inputParent.find('input[type="file"]');
      let fileName = inputFile[0].files[0].name;

      addError();
      changeLabelName();
      function addError() {
        if (!checkFile()) {
          inputParent.addClass('error');
        } else {
          inputParent.removeClass('error');
        }
      }
      function changeLabelName() {
        let label = inputParent.find('.gfield_label');
        let labelFirstText = '';

        if (checkFile()) {
          labelFirstText = label[0].innerText;
          inputParent.addClass('success');
          label.attr('data-first-text', labelFirstText);
          label.text(fileName);
        }
      }
      function checkFile() {
        let fileFormats = []; //takes all formats who can use
        let fileSize = Math.floor(Number(inputFile[0].files[0].size) / 1000);
        let fileFormat = fileName.split('.').pop();
        takeFileFormats(fileFormats);

        return (
          fileFormats.includes(fileFormat) && maxFileSizeCanBeeLoad > fileSize
        );
      }
      function takeFileFormats(arr) {
        const inputString = inputParent.find('.gform_fileupload_rules')[0]
          .innerText;
        const words = inputString.split(' ');
        $.each(words, function (index, word) {
          if (word.endsWith(',')) {
            arr.push(word.replace(',', ''));
          }
        });
      }
    });
  }
  stylingUploadInputGravityForm();
  function clearInputFile() {
    $(document).on('click', '.gfield--type-fileupload', function (e) {
      let parent = $(this);
      if (parent.hasClass('success')) {
        e.preventDefault();
        let inputFile = parent.find('input[type="file"]');
        let label = parent.find('label');
        inputFile.val('');
        parent.removeClass('success');
        label.text(label.attr('data-first-text'));
      }
    });
  }
  clearInputFile();
});

function madeSliderForQuoteBlock() {
  let sliders = $('.quote_block__body');
  if (sliders.length === 0) return;

  sliders.each(function (idx, item) {
    const $item = $(item);
    const controls = $(item)
      .closest('.quote-block-section')
      .find('.slider_controls');

    $item
      .slick({
        arrows: true,
        infinite: true,
        prevArrow: controls.find('.slick-prev'),
        nextArrow: controls.find('.slick-next'),
        rtl: document.dir === 'rtl',
      })
      .on('beforeChange', updateSliderCounter);
  });
}

madeSliderForQuoteBlock();

timelineShowMore();

$('.home-hero-slide__title').on('click', function () {
  const $this = $(this);
  if ($this.closest('.home-hero__slide').hasClass('is-active')) return;
  $('.home-hero__slide').removeClass('is-active');
  $this.closest('.home-hero__slide').addClass('is-active');
  if (Foundation.MediaQuery.is('medium down')) {
    const slideHeight = $('.home-hero-slide-tab__inner').first().outerHeight();
    const animationArgs = {
      duration: 650,
      queue: false,
      easing: 'easeInOutCubic',
    };
    $('.home-hero-slide-tab').animate({ height: 0 }, animationArgs);
    $this.next().animate({ height: slideHeight }, animationArgs);
  }
});
$('.home-hero-slide-tab__content').matchHeight({
  byRow: false,
});

downloadBlocksShowMore();

$('a[href*="#"]').on('click', function (e) {
  const $this = $(this);
  if (
    $this.closest('[data-tabs]').length ||
    $this.closest('[data-accordion]').length
  )
    return;

  const href = $this.attr('href');
  if (href.indexOf('#') === 0) {
    e.preventDefault();
    scrollToBlock(href);
  } else {
    let split = href.split('#');
    let location = window.location.href.replace('#', '');
    if (location === split[0]) {
      e.preventDefault();
      scrollToBlock('#' + split[1]);
    }
  }
});
//Smooth scroll to another page
let locationHash = window.location.hash;
if (locationHash) {
  window.location.hash = '';
  scrollToBlock(locationHash);
}

function madeSliderFor_slider_and_images_block() {
  let sections = $('.slider_and_images');
  if (sections.length === 0) return;
  // sections.each(function (_, section) {
  let slider = $(sections).find('.slider');
  let controls = $(sections).find('.button-slider__buttons');

  slider
    .slick({
      arrows: true,
      infinite: true,
      variableWidth: true,
      rows: 0,
      prevArrow: controls.find('.slick-prev'),
      nextArrow: controls.find('.slick-next'),
      rtl: document.dir === 'rtl',
    })
    .on('beforeChange', updateSliderCounter);
  slider.slick('slickGoTo', 0);
}

madeSliderFor_slider_and_images_block();

let sliderInterval;
$('.messen-slider-slide-wrapper').on('click', function () {
  const $this = $(this);

  if ($this.hasClass('is-active')) return;
  const slideWrappers = $this
    .closest('.messen-slider')
    .find('.messen-slider-slide-wrapper');

  slideWrappers.removeClass('is-active');
  $this.addClass('is-active');

  if (Foundation.MediaQuery.is('medium down')) {
    const slides = $this.closest('.messen-slider').find('.messen-slider-slide');
    let maxHeight = slides.first().outerHeight();
    slideWrappers.animate(
      { height: 80 },
      {
        duration: 300,
        queue: false,
      }
    );
    $this.animate(
      { height: maxHeight },
      {
        duration: 300,
        queue: false,
      }
    );
  }

  clearInterval(sliderInterval);
  sliderInterval = setInterval(toggleMesseSlider, 5000);
});

$('.messen-slider-slide').matchHeight({
  byRow: false,
});

sliderInterval = setInterval(toggleMesseSlider, 5000);

function toggleMesseSlider() {
  if (Foundation.MediaQuery.is('medium down')) return;

  const activeSlide = $('.messen-slider-slide-wrapper.is-active');
  const nextSlide = activeSlide.is(':last-child')
    ? activeSlide
        .closest('.messen-slider')
        .find('.messen-slider-slide-wrapper:first-child')
    : activeSlide.next();

  activeSlide.removeClass('is-active');
  nextSlide.addClass('is-active');
}

loadMoreKarriers();

initSliderOnMobile(
  '.contact_cards_slider',
  {
    appendArrows: '.js-cards-slider__footer .slider-buttons',
    rows: 0,
    infinite: true,
    variableWidth: true,
  },
  'medium'
);

$('.contact_cards_slider').on('beforeChange', updateSliderCounter);

$('.contact_cards__button button').on('click', function () {
  const $this = $(this);
  $('.contact_cards__body').toggleClass(function () {
    const cardsWrapper = $('.contact-cards-wrapper');
    const showMoreText = $this.attr('data-show-more');
    const showLessText = $this.attr('data-show-less');
    if ($(this).hasClass('is-active')) {
      cardsWrapper.animate(
        { height: cardsWrapper.attr('data-max-height') },
        500
      );
      $this.text(showMoreText);
    } else {
      cardsWrapper.animate(
        { height: cardsWrapper.attr('data-initial-height') },
        500
      );
      $this.text(showLessText);
    }

    return 'is-active';
  });

  $(this).focus();
});

$(window).on('load resize', function () {
  if (Foundation.MediaQuery.is('medium')) {
    const cardsWrapper = $('.contact-cards-wrapper');
    const initialHeight = $('.contact_cards__body').outerHeight();
    const cards = cardsWrapper.find('.card');
    let maxHeight = 0;
    cards.each((idx, card) => {
      if (idx === 0 || idx === 3 || idx === 6) {
        maxHeight += $(card).outerHeight(true);
      }
    });
    cardsWrapper.height(maxHeight);
    cardsWrapper.attr('data-initial-height', initialHeight);
    cardsWrapper.attr('data-max-height', maxHeight);
  }
});

$(document).on('click', '.load-more-posts', function () {
  const $wrapper = $(this).closest('.js-posts-wrapper');
  const offset = $wrapper.find('.post-card').length;

  load_posts($wrapper, { offset });
});
$(document).on('click', '.category-button', function () {
  const $this = $(this);
  const $wrapper = $this.closest('.blog-section').find('.js-posts-wrapper');
  let cat = '';
  if (!$this.hasClass('current')) {
    cat = $(this).data('cat');
  }
  load_posts($wrapper, { cat });
});

function load_posts($wrapper, data) {
  if ($wrapper.hasClass('loading')) return;

  const defaults = { action: 'load_more_posts' };
  defaults.cat = $wrapper.attr('data-current-cat');

  $.extend(defaults, data);

  $.ajax({
    url: ajax_object.ajax_url,
    type: 'GET',
    data: defaults,
    beforeSend: function () {
      $wrapper.addClass('loading');
    },
    success: function (resp) {
      $wrapper.html(resp);
      $wrapper.attr('data-current-cat', data.cat);
      if (!data.offset) {
        $('.category-button').removeClass('current');
        $(`.category-button[data-cat="${data.cat}"]`).addClass('current');
      }
    },
    complete: function () {
      $wrapper.removeClass('loading');
      $(window).trigger('ajaxRequestEnd');
    },
  });
}

$('.cards-slider__slider').each(function (i, item) {
  const $item = $(item);
  const $buttonsContainer = $item
    .closest('.cards-slider')
    .find('.cards-slider__buttons');

  $item.on('beforeChange', updateSliderCounter);

  $item.slick({
    rows: 0,
    infinite: true,
    appendArrows: $buttonsContainer,
    variableWidth: true,
    rtl: document.dir === 'rtl',
  });
});

function sameHeightFor_cardsSlider() {
  const sections = $('.cards-slider__slider');
  if (sections.length === 0) return false;
  // sections.each(function (_, section) {
  //   $(section).find('.cards-slider__slide p').matchHeight();
  // });
  $('cards-slider__slide p').matchHeight();
}
sameHeightFor_cardsSlider();
$(window).on('load resize', function () {
  $(document.documentElement).css('--app-height', window.innerHeight + 'px');
});

$(document).on('click', `a[href="#show_cookie"]`, function (e) {
  e.preventDefault();
  const cookie = $('.borlabs-cookie-preference');
  if (cookie) cookie.click();
});

/* global ajax_object */

import Foundation from './foundation';

export function initSliderOnMobile(
  selector,
  options = {},
  breakpoint = 'medium'
) {
  $(window).on('load changed.zf.mediaquery ajaxRequestEnd', function () {
    $(selector).each(function (idx, item) {
      let isSlider = $(item).hasClass('slick-initialized');

      if (Foundation.MediaQuery.is(breakpoint)) {
        if (isSlider) {
          $(item).slick('unslick');
        }
      } else if (!isSlider) {
        $(item).slick(options);
      }
    });
  });
}

export function toggleVideoPlay() {
  const videoBlocks = document.querySelectorAll('.video_block');
  if (videoBlocks.length > 0) {
    videoBlocks.forEach(function (block) {
      const video = block.querySelector('video');

      if (video) {
        video.addEventListener('click', function () {
          if (video.paused) {
            block.classList.add('active_video');
            this.play();
          } else {
            block.classList.remove('active_video');
            this.pause();
          }
        });
      }
    });
  }
}

export function updateSliderCounter(event, slick, currentSlide, nextSlide) {
  const parentSection = slick.$slider.closest('section');
  const counterWrap = parentSection.find('.slider__counter');
  const current =
    typeof nextSlide !== 'undefined' ? nextSlide + 1 : slick.currentSlide + 1;

  const currentFormatted = current.toString().padStart(2, '0');

  counterWrap.find('.current').text(currentFormatted);
}

export function customSelect(element) {
  const $this = $(element),
    selectOptions = $this.children('option').length;

  if ($this.hasClass('hide-select')) return;

  $this.addClass('hide-select');
  $this.wrap('<div class="select"></div>');
  $this.after('<div class="custom-select"></div>');

  const $customSelect = $this.next('div.custom-select');
  const selectLabel = $this.children('option[selected]').length
    ? $this.children('option[selected]').text()
    : $this.children('option').eq(0).text();
  $customSelect.text(selectLabel);

  const $optionlist = $('<ul />', {
    class: 'select-options',
  }).insertAfter($customSelect);

  for (let i = 0; i < selectOptions; i++) {
    $('<li />', {
      text: $this.children('option').eq(i).text(),
      rel: $this.children('option').eq(i).val(),
    }).appendTo($optionlist);
  }

  const $optionlistItems = $optionlist.children('li');

  $customSelect.click(function (e) {
    e.stopPropagation();
    $('div.custom-select.active')
      .not(this)
      .each(function () {
        $(this).removeClass('active').next('ul.select-options').hide();
      });
    $(this).toggleClass('active').next('ul.select-options').slideToggle();
  });

  $optionlistItems.click(function (e) {
    e.stopPropagation();
    $customSelect.text($(this).text()).removeClass('active');
    $this.val($(this).attr('rel')).trigger('change');
    $optionlist.slideUp();
  });

  $(document).click(function (e) {
    const target = $(e.target);
    if (target.hasClass('custom-select') || target.closest('.select').length)
      return;

    $customSelect.removeClass('active');
    $optionlist.slideUp();
  });

  $(document).ready(function () {
    $customSelect.removeClass('active');
    $optionlist.hide();
  });
}

export function timelineShowMore() {
  let sections = $('.timeline');
  if (sections.length === 0) return false;

  sections.each(function (_, section) {
    if (!$(section).find('.more-than-3')) return false;
    const button = $(section).find('.button');
    let timelineWrapper = $(section).find('.timeline__wrapper'),
      opened = false;

    button.on('click', function () {
      if (opened === false) {
        $('html, body').animate({
          scrollTop: $(window).scrollTop(),
        });
        timelineWrapper.slideDown(300);
        opened = true;
        $(button).text($(this).attr('data-hidden'));
      } else {
        timelineWrapper.slideUp(300);
        opened = false;
        $(button).text($(this).attr('data-show-more'));
      }

      $(button).css('background-color', 'transparent');
      $(button).css('color', '#376135');
    });
  });
}

export function downloadBlocksShowMore() {
  const sections = $('.download_logos');
  if (sections.length === 0) return false;

  sections.each(function (_, section) {
    if (!$(section).find('.more_than_3')) return false;

    const button = $(section).find('.download_logos__show_more button');
    let timelineWrapper = $(section).find('.cards__wrapper');

    button.on('click', function () {
      timelineWrapper[0].style.maxHeight =
        timelineWrapper[0].scrollHeight + 'px';

      $('.download_logos__show_more').css('display', 'none');
      $(section).find('.card:last').css('margin-bottom', '0');
    });
  });
}

export function scrollToBlock(id) {
  const block = $(id);

  if (!block.length) return;

  let offset = $('.header').outerHeight();
  if (!offset) {
    offset = 0;
  }

  setTimeout(function () {
    $('html,body').animate(
      {
        scrollTop: $(id).offset().top - offset,
      },
      400
    );
  }, 500);
}

export function loadMoreKarriers() {
  let currentPage = 1;
  $(document).on('click', '.list_jobs .button', function () {
    let button = $(this);
    $.ajax({
      type: 'POST',
      url: ajax_object.ajax_url,
      dataType: 'json',
      data: {
        action: 'karriere_load_more',
        paged: currentPage,
      },
      success: function (res) {
        currentPage++;
        if (currentPage >= res.max) {
          $('.list_jobs__button').hide();
        }
        $('.list_jobs__items').append(res.html);
        button.blur();
      },
    });
  });
}

//if forms successfully========
$(document).on('gform_confirmation_loaded', function () {
  const confirmationWrapper = $('.gform_confirmation_wrapper');

  if (confirmationWrapper.closest('.product-archive-form')) {
    confirmationWrapper.closest('.product-archive-form').addClass('success');
    $(this).find('.product-archive-form__subtitle').remove();
    //check page (is Karriere?)
    const currentURL = window.location.href;
    if (currentURL.indexOf('karriere') !== -1) {
      const heroTitle = $('.hero_block__info h1');
      const formTitle = $('.product-archive-form__title');
      formTitle.text(heroTitle[0].innerText);
      formTitle.addClass('title_was_changed');
    }
  }

  if (confirmationWrapper.closest('.newsletter-form')) {
    $(this).find('.newsletter-form__description').remove();
  }

  if (confirmationWrapper.closest('.download_form')) {
    $(this).find('.download_form__form_block .overline').remove();
  }
});
//========================================================

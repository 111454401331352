$(document).on('gform_post_render', function () {
  // eslint-disable-next-line no-undef
  gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj) {
    optionsObj.onUpdateDatepicker = function (options) {
      const selects = options.dpDiv.find('select');
      selects.each(function (_, item) {
        $(item).select2({
          minimumResultsForSearch: Infinity, //remove reach field
          dropdownParent: $('#ui-datepicker-div'),
        });
      });
    };
    return optionsObj;
  });
});
